import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/bdTechies/prozuktischool.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Divider, SEO, Text } from '../views/components';
import AuthorList from '../views/pages/Authors/AuthorList';
import authors from '../../content/author.yaml';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    {
      /* import YAML from 'yamljs';
      const authors = YAML.parse(authorData); */
    }
    <SEO pageTitle="নির্দেশনা" pagePath="/contribution-guide/" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  লেখালেখির নিয়মনীতি
      </Text>
      <Divider mdxType="Divider" />
      <p>{`আসসালামু আলাইকুম। প্রযুক্তি স্কুলের পক্ষ থেকে আপনাকে জানাই স্বাগতম। এই লেখাটির মাধ্যমে আমরা আপনাকে জানানোর চেষ্টা করবো প্রযুক্তি স্কুলের উন্নয়নকল্পে আপনি কীভাবে অবদান রাখতে পারেন।`}</p>
      <h3 {...{
        "id": "ফিচার-অথবা-বাগ-রিপোর্ট",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%AB%E0%A6%BF%E0%A6%9A%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A6%A5%E0%A6%AC%E0%A6%BE-%E0%A6%AC%E0%A6%BE%E0%A6%97-%E0%A6%B0%E0%A6%BF%E0%A6%AA%E0%A7%8B%E0%A6%B0%E0%A7%8D%E0%A6%9F",
          "aria-label": "ফিচার অথবা বাগ রিপোর্ট permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#7950F2",
            "stroke": "#7950F2",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`ফিচার অথবা বাগ রিপোর্ট`}</h3>
      <p>{`প্রযুক্তি স্কুল পরিদর্শনের সময় আপনার চোখে যদি কোনো অসঙ্গতি (বাগ) ধরা পড়ে অথবা কোনো ফিচারের কমতি আছে বলে যদি মনে হয় তবে আমাদের `}<a parentName="p" {...{
          "href": "https://github.com/bdTechies/prozuktischool.com"
        }}>{`গিটহাব প্রজেক্টে`}</a>{` একটা ইস্যু তেরি করে আমাদের জানাতে পারেন।`}</p>
      <h3 {...{
        "id": "ফিচার-অথবা-বাগ-নিয়ে-কাজ-করা",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%AB%E0%A6%BF%E0%A6%9A%E0%A6%BE%E0%A6%B0-%E0%A6%85%E0%A6%A5%E0%A6%AC%E0%A6%BE-%E0%A6%AC%E0%A6%BE%E0%A6%97-%E0%A6%A8%E0%A6%BF%E0%A7%9F%E0%A7%87-%E0%A6%95%E0%A6%BE%E0%A6%9C-%E0%A6%95%E0%A6%B0%E0%A6%BE",
          "aria-label": "ফিচার অথবা বাগ নিয়ে কাজ করা permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#7950F2",
            "stroke": "#7950F2",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`ফিচার অথবা বাগ নিয়ে কাজ করা`}</h3>
      <p>{`এই প্রজেক্টের যেকোনো ফিচার বা বাগ নিয়ে কাজ শুরু করার আগে তা আমাদেরকে সংশ্লিষ্ট গিটহাব ইস্যুর মাধ্যমে জানাতে হবে। এরপর প্রথমেই আপনাকে আমাদের গিটহাব রিপোটি ফোর্ক করে নিতে হবে। ফোর্ক করা রিপোটি আপনার কম্পিউটারে ক্লোন করে, প্রয়োজনীয় ডিপেনডেন্সি ইন্সটল করে সেখান থেকে আপনি কাজ শুরু করতে পারেন। গিট ব্রাঞ্চিংয়ের ক্ষেত্রে আমরা `}<a parentName="p" {...{
          "href": "https://danielkummer.github.io/git-flow-cheatsheet/"
        }}>{`git flow`}</a>{` অনুসরণ করার চেষ্টা করি।`}</p>
      <h3 {...{
        "id": "গিট-কমিট-মেসেজ-লেখার-নিয়ম",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#%E0%A6%97%E0%A6%BF%E0%A6%9F-%E0%A6%95%E0%A6%AE%E0%A6%BF%E0%A6%9F-%E0%A6%AE%E0%A7%87%E0%A6%B8%E0%A7%87%E0%A6%9C-%E0%A6%B2%E0%A7%87%E0%A6%96%E0%A6%BE%E0%A6%B0-%E0%A6%A8%E0%A6%BF%E0%A7%9F%E0%A6%AE",
          "aria-label": "গিট কমিট মেসেজ লেখার নিয়ম permalink",
          "className": "header-anchor-icon before"
        }}><svg parentName="a" {...{
            "version": "1.1",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 162.656 162.656",
            "xmlSpace": "preserve",
            "width": "0.6em",
            "height": "0.6em",
            "fill": "#7950F2",
            "stroke": "#7950F2",
            "strokeWidth": "0"
          }}><path parentName="svg" {...{
              "d": "M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z"
            }}></path>{` `}<path parentName="svg" {...{
              "d": "M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z"
            }}></path>{` `}</svg></a>{`গিট কমিট মেসেজ লেখার নিয়ম`}</h3>
      <p>{`আপনি যদি সরাসরি আমাদের গিট প্রজেক্টে কন্ট্রিবিউট করতে চান তবে কমিট ম্যাসেজ লেখার ক্ষেত্রে নিচের নিয়মগুলো মনে রাখার চেষ্টা করবেন। কমিট ম্যাসেজে ইমোজি ব্যবহারকে আমরা অনেক গুরুত্ব দিয়ে থাকি।`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`# কিছু যোগ করলে
➕ Add new post
অথবা
:heavy_plus_sign: Add new post

# কিছু ঠিক করলে
🐛 Fix post spelling
অথবা
:bug: Fix post spelling

# কিছু বাদ দিলে
🗑 Remove extra spacing
অথবা
:wastebasket: Remove extra spacing

# কিছু রিফ্যাক্টর করলে
♻️ Refactor post summary card
অথবা
:recycle: Refactor post summary card

# কিছু চালু করলে
▶️ Start automatic post formatting feature
অথবা
:arrow_forward: Start automatic post formatting feature

# কিছু থামিয়ে দিলে
⏹️ Stop automatic post formatting feature
অথবা
:stop_button: Stop automatic post formatting feature

# কিছু রিফরম্যাট করলে
📄 Reformat post code example
অথবা
:page_facing_up: Reformat post code example

# কিছু বিল্ড করলে
📦 Build sqlite package for windows
অথবা
:package: Build sqlite package for windows

# কিছু আপডেট করলে
↕️ Update dependencies
অথবা
:arrow_up_down: Update dependencies

# কিছু রিলিজ করলে
🔖 Release v1.0.0
অথবা
:bookmark: Release v1.0.0

# কোন নির্দেশনা যোগ করলে
📝 Document contribution guide
অথবা
:pencil: Document contribution guide

# কিছু অপটিমাইজ করলে
⚡️ Optimize build process
অথবা
:zap: Optimize build process`}</code></pre></div>
      <p>{`[`}<em parentName="p">{`এই নীতিমালাটি এখনো খসরা পর্যায়ে আছে। ধীরে ধীরে এটি আপডেট করে চূড়ান্ত কিছু একটায় পরিণত করা হবে ইনশা আল্লাহ্।`}</em>{`]`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      